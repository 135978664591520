//@import "reset";
@import "variables";
@import "svg-icons";

// Variables ///////////////////////////////////////////////////////////////////////////////////////

$color_link_hover: #fd6282;
$color_button: #5a9e74;
$color_button_hover: #477e5c;
$color_footer: #f5f5f5;

// Code ////////////////////////////////////////////////////////////////////////////////////////////

pre.highlight {
  margin-bottom: 1em;

  code {
    background-color: #fff;
    margin: 0;
    padding: 0;
    border-style: solid 1em;
    display: block;
    overflow: auto;
    //white-space: pre;
    word-wrap: normal;
  }

  .lineno {
    display: inline-block;
    padding: 0 5px;
    border-right: 1px solid #ccc;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .rouge-table {
    margin-bottom: 0;
    border: none;
  }

  .rouge-code {
    font-size: 0.9rem;
  }

  .highlight .gutter, .lineno {
    color: #ccc;
  }
}

// Sections ////////////////////////////////////////////////////////////////////////////////////////

.modSectionHeader .special-title h2::after {
  background: $color_button;
}

.modGallery .divider li {
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
}

// Footer //////////////////////////////////////////////////////////////////////////////////////////

#footer {
  background: $color_footer;
}
#footer h1, #footer h2, #footer h3, #footer h4, #footer h5, #footer h6 {
  color: #000;
}
#footer ul a:hover {
  color: $color_link_hover;
}

// Links ///////////////////////////////////////////////////////////////////////////////////////////

a {
  color: $blue;
}
h4 a {
  color: #999;
}
.modBlogPost h4 a:hover, .links a:hover, .pager a:hover, a:hover, a:focus {
  color: $color_link_hover;
}

// Buttons /////////////////////////////////////////////////////////////////////////////////////////

.button {
  background: $color_button;
  border: 2px solid $color_button;
}
.button:hover {
  background-color: $color_button_hover;
}

// Search Box //////////////////////////////////////////////////////////////////////////////////////

#search_box {
  font-size: 100%;

  #search_group {
    width: 300px;
    margin: 0 auto;

    #search-input {
      font-size: 18px;
      width: 300px;
      border: none;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) inset;
      padding: 0.3em 0.6em;
      background-color: #f6f5ea;
      margin-left: 0;
    }
  }
}

@import "highlights";
